<template>
    <div style="background:#e67a00;text-align:center;">
        <!-- <b-sidebar backdrop aria-labelledby="sidebar-no-header-title" no-header id="extra_buttons" width="170px" style="background:#e67a00;direction:ltr" right title="سند صرف" shadow > -->
        <b-modal
            v-show="!show"
            id="buttons_close_cashair"
            ref="buttons_close_cashair"
            :title="$parent.lang.extra_buttons"
            size="lg"
            :footer-bg-variant="`warning`"
            :header-bg-variant="`dark`"
            :header-text-variant="`light`"
            hide-backdrop
            lazy
        >
            <template #modal-header >
                <div class="w-100 flexit" style="direction:rtl;text-align:center;">
                    <div style="margin-bottom:5px!important;font-size:1.3rem;border:2px solid #fff !important;width:150px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                        {{ $parent.$parent.lang.clode_shift }}
                    </div>
                </div>
            </template>
            <div style="text-align:center;direction:rtl;">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="8">{{$parent.$parent.lang.add_your_cash}}</th>
                        </tr>
                        <tr>
                            <th>1 SAR</th>
                            <th>5 SAR</th>
                            <th>10 SAR</th>
                            <th>20 SAR</th>
                            <th>50 SAR</th>
                            <th>100 SAR</th>
                            <th>200 SAR</th>
                            <th>500 SAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><b-form-input class="inborder" v-model="close.riyal" /></td>
                            <td><b-form-input class="inborder" v-model="close.riyal5" /></td>
                            <td><b-form-input class="inborder" v-model="close.riyal10" /></td>
                            <td><b-form-input class="inborder" v-model="close.riyal20" /></td>
                            <td><b-form-input class="inborder" v-model="close.riyal50" /></td>
                            <td><b-form-input class="inborder" v-model="close.riyal100" /></td>
                            <td><b-form-input class="inborder" v-model="close.riyal200" /></td>
                            <td><b-form-input class="inborder" v-model="close.riyal500" /></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="8">{{ $parent.$parent.lang.total }} ({{ closeTotal }})</th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </div>
    
        <template #modal-footer="{ close }" >
            <div class="w-100" style="direction:rtl;text-align:center;">
                <b-button type="button" @click='closeShift()' style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.$parent.lang.save }}</b-button>
                <b-button type="button" @click='close()' style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.$parent.lang.close }}</b-button>
            </div>
        </template>
    </b-modal>
    </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';

export default{
    data() {
        return {
            close:{
                riyal: '',
                riyal5: '',
                riyal10: '',
                riyal20: '',
                riyal50: '',
                riyal100: '',
                riyal200: '',
                riyal500: '',
            },
            show: false,
            fieldRules: [
                v => !!v || 'الحقل مطلوب',
            ],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            mobile: '',
            full_name:'',
            received_total:'',
            notes:'',
            selected:null,
            vselected:null,
            options:[],
            voptions:[],
            voutcher_reson:'',
            resons:[
                {
                    value: 1,
                    text: this.$parent.lang.buy_goods
                },
                {
                    value: 2,
                    text: this.$parent.lang.return_invoice
                },
                {
                    value: 3,
                    text: this.$parent.lang.sallary
                },
                {
                    value: 4,
                    text: this.$parent.lang.expenses
                },
                {
                    value: 5,
                    text: this.$parent.lang.buy_devices
                },
                {
                    value: 6,
                    text: this.$parent.lang.office_rent
                },
                {
                    value: 7,
                    text: this.$parent.lang.pay_for_bills
                },
                {
                    value: 8,
                    text: this.$parent.lang.others
                },
            ],
            vatopt:[
                {
                    value: 1,
                    text: 'لا تحتوي على ضريبة'
                },
                {
                    value: 2,
                    text: 'المبلغ شامل الضريبة'
                },
                {
                    value: 3,
                    text: 'المبلغ غير شامل الضريبة'
                },
            ],

        }
    },
    computed:{
        closeTotal: function(){
            let t = 0;
            // console.log("this.close.riyal",!isNaN(this.close.riyal));
            t = parseFloat(t) + (this.close.riyal == '' ? 0 : parseFloat(this.close.riyal))
            t = parseFloat(t) + (this.close.riyal5 == '' ? 0 : parseFloat(this.close.riyal5)*5)
            t = parseFloat(t) + (this.close.riyal10 == '' ?0 : parseFloat(this.close.riyal10)*10)
            t = parseFloat(t) + (this.close.riyal20 == '' ? 0 : parseFloat(this.close.riyal20)*20)
            t = parseFloat(t) + (this.close.riyal50 == '' ? 0 : parseFloat(this.close.riyal50)*50)
            t = parseFloat(t) + (this.close.riyal100 == '' ? 0 : parseFloat(this.close.riyal100)*100)
            t = parseFloat(t) + (this.close.riyal200 == '' ? 0 : parseFloat(this.close.riyal200)*200)
            t = parseFloat(t) + (this.close.riyal500 == '' ? 0 : parseFloat(this.close.riyal500)*500)
            return this.$RoundNum(t,2);
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
    methods: {
        closeShift(){
            const post = new FormData();
            post.append("type" , "closeShift");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            Object.keys(this.close).forEach(key=>{
                post.append('data['+key+']',this.close[key]);
            })
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;

                    this.$router.push({path: '/logout'})
                }
            );

        },
        clientRep(){
            //
        },
        
        checkData(){
            // if(typeof document.getElementById('add_outreceipt') !== 'undefined'
            //     || !!document.getElementById('add_outreceipt') != true
            // )
            // {
            //     if(document.getElementById('add_outreceipt').style.display != 'none'){
            //         if(this.received_total == 0){
            //             //this.received_total = this.$parent.cardrows[this.$parent.activeClick].remaining;
            //         }
            //     }else{
            //         this.received_total = 0;
            //     }
            // }
        },
        addVoucher(){
            if(!this.$refs.addform.validate()){

                return false;
            }
            const post = new FormData();
            post.append("type",'addOVouchter');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[type]',2)
            post.append('data[mobile]',this.mobile)
            post.append('data[full_name]',this.full_name)
            post.append('data[amount]',this.received_total)
            post.append('data[notes]',this.notes)
            post.append('data[date]',this.date)
            post.append('data[selected]',this.selected)
            post.append('data[vselected]',this.vselected);
            // post.append('data[dailydate]',this.$parent.cardrows[this.$parent.activeClick].edate ?? 0);
            // post.append('data[dailyid]',this.$parent.cardrows[this.$parent.activeClick].id ?? 0);
                       
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    this.modal = false;
                    this.mobile = '';
                    this.full_name ='';
                    this.received_total ='';
                    this.notes = '';
                    this.$snotify.create({
                    title: ' ',
                        body: this.$parent.lang.added_successfuly,
                        config: {
                            position: SnotifyPosition.leftTop,
                            type: SnotifyStyle.success,
                            timeout: 5000,
                            showProgressBar: true,
                            closeOnClick: true,
                        }
                    })
                    document.getElementById('ohidid').click();
                    this.$parent.getVouchers();
                }
            )
        }
    },
}
</script>
<style>
.v-input__icon--append i{
    font-size:3em !important;  
    color:red !important;
}
.scrollMe{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    position:absolute;
    text-align:center;
    left:2px;
    width:50px;
    top:130px;
    bottom:40px;
}
th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
._invoicetables_{
    max-height:250px;
    min-height:250px;
    overflow: auto;
}
#countbtns{
    background:#eee;
    border:1px solid #ccc;
    text-align:center;
    position: absolute;
    bottom: 1px;
    left:1px;
    right:1px;
    display:flex;
    flex-wrap: nowrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
._mxmnheight_{
    max-height:calc(100vh - 100px);
    min-height:calc(100vh - 100px);
    position: relative;
}
._mxmnheight_-200{
    max-height:calc(100vh - 320px);
    overflow: auto;
}
.wp-100{
    width: 145px;
    padding:10px;
    margin-inline-start: 3px;
}
.wp-150{
    padding-top:10px;
    width: calc(95%/5);
    height:130px;
    margin-inline-start: 3px;
    margin-top: 3px;
    background:#fff;
    color:#000;
    transition: 1s;
}
.wp-150:hover{
    /* background:#222;
    color:#fff; */
    transition: 1s;
}
.itemrowstd{
    transition: 1s;
}
.btn-gray{
    background:#fff;
    color: #000;
    border-radius: 7px;
}
.btn-wght{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    position: relative;
    padding-top:10px !important;

}
.btn-wght:active,.btn-wght:hover,.btn-wght:visited{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    
}
.btnorange,.btnorange:active,.btnorange:hover,.btnorange:visited{
    background: rgb(230, 122, 0) !important;
    color: #fff !important;
    font-weight: 800;
    border-radius: 7px;
}
.btn-yallow,.btn-yallow:active,.btn-yallow:hover,.btn-yallow:visited{
    background: darkolivegreen !important;
    color:black !important;
    border-radius: 7px;
}
.btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-radius: 7px;
}
.rowPrice{
    position: absolute;
    top:0;
    left:0;
    right: 0;
    background:rgb(230, 122, 0);
    color:#fff;
    text-align:center;
    padding:3px;
    border:1px solid #000;
}
.rrowPrice{
    position: absolute;
    bottom:0;
    left:0;
    right: 0;
    background:black;
    color:#fff;
    text-align:center;
    padding:3px;
    border:1px solid #000;
}
.gradBG{
    background: rgb(31,43,153) !important;
background: linear-gradient(180deg, rgba(31,43,153,1) 0%, rgba(31,87,153,1) 3%, rgba(31,89,153,1) 18%, rgba(31,103,153,1) 50%, rgba(31,83,153,1) 82%, rgba(31,64,153,1) 97%, rgba(31,43,153,1) 100%) !important;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
.border2black,.modal-content{
    border:2px solid #000 !important;
}
.positionEnd,.positionEnd:hover{
    background:transparent !important;
    border:none;
    box-shadow: none;
    color:#fff !important;
}
.flexit{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    justify-items: center;
    align-items: center;
}
.modal-header,.modal-footer{
    padding:5px 10px 0 0 !important;
    background:black !important ;
}
.modal-footer > * {
    margin:0;
}
.modal{
    top:40px;
}
.catBTN{
    width:145px !important;
}
.displayFlexNo{
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items:center;
}
._actionBTNs_{
    border:2px solid #fff !important;
    width:118px !important;
    font-size:.75rem !important;
    color:#fff;
    margin-inline-end: 5px;
    font-size:.8rem;
}
.qtyBTN{
    width:40px;
    margin-inline-start:5px;
    font-size:.7rem;
}
.actBTN{
    width:45%;
    margin-inline-start:5px;
    margin-bottom:5px;
}
.w100{
    width:100px;
}
.moneyTables{
    position:absolute;
    top:385px;
    left:0px;
    right:5px;
    text-align:center;
}
@media screen and (max-device-width: 1400px)
              and (min-device-width: 480px)  {
    .itemrowstd{
        font-size:.8rem !important;
        height:30px !important
    }
    .w100{
        font-size: .6rem !important;
        width:80px;
    }
    .actBTN{
        font-size:.7rem
    }
    *{
        font-size:0.645rem;
    }
    .catBTN{
        width:95px !important;
        font-size:0.645rem;
    }
    ._actionBTNs_{
        margin-top:10px;
        width:115px !important;
        font-size:0.5rem !important;
        margin-inline-end:5px
    }
    /* ._invoicetable_{
        max-height:200px;
        min-height:200px;
        overflow: auto;
    } */
    .moneyTable{
        top:260px;
    }
    
}
.floatingButtom,.floatingButtom:hover,.floatingButtom:active{
    position:fixed;
    bottom:30px;
    left:30px;
    width:150px;
    /* writing-mode: vertical-rl; */
    background:black;
    color:#fff;
    cursor:pointer;
    padding:5px;
    text-align:center;
}
.startRight{
    margin-inline-end:20px !important;
}
.startRight .modal-dialog{
    margin-inline-end:30px !important;
    box-shadow: 0px 5px 5px #00000005 !important;
}
</style>